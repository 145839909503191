<template>
  <v-col cols="12" style="overflow: visible">
    <v-row
      v-if="$vuetify.breakpoint.smAndUp"
      justify="space-around"
      :class="isHighZoomOrSmallScreen ? '' : 'mb-4'"
    >
      <div v-for="area in focusAreas">
        <v-hover :key="area.id" v-slot="{ hover }">
          <v-card
            :id="area.id"
            :color="isFocusAreaSelected(area) ? area.color : ''"
            :outlined="isFocusAreaSelected(area) ? true : false"
            @click="selectFocusArea(area)"
            :elevation="hover || isFocusAreaSelected(area) ? '15' : ''"
            class="pa-3 ma-3"
            :min-height="focusAreaSquareLength"
            :height="focusAreaSquareLength"
            :width="focusAreaSquareLength"
            ripple
          >
            <v-row justify="center" class="mb-3 fill-height">
              <v-col cols="10" align-self="center">
                <v-img
                  :src="require('@/assets/images/wellness/' + area.imageUrl)"
                  :max-height="imageHeight"
                  contain
                />
              </v-col>
              <v-col cols="12" class="py-0" align-self="end">
                <p
                  class="mb-0 text-h6"
                  :class="isFocusAreaSelected(area) ? 'font-weight-bold' : ''"
                >
                  {{ area.title }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
        <v-row v-if="openedDialogWithPain" :width="focusAreaSquareLength">
          <v-col cols="12" style="height: 60px">
            <v-btn
              icon
              class="no-hover align-with-pain-button ml-2"
              :ripple="false"
              color="primary"
              @click="$emit('updatePainArea')"
              v-show="isShowingPainButton(area.value)"
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
              <span class="ml-2">
                {{ $t("onboarding.endUserFocusArea.updatePainArea") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-row>

    <div v-else v-for="area in focusAreas">
      <v-row
        class="mx-2"
        :key="area.id"
        :class="isFocusAreaSelected(area) ? 'selected' : ''"
        @click="selectFocusArea(area)"
      >
        <v-col cols="5" class="py-0">
          <v-card
            :id="area.id"
            :color="area.color"
            class="pa-3 ma-3"
            :min-height="focusAreaSquareLength"
            :width="focusAreaSquareLength"
          >
            <v-row justify="center" align="center" class="mb-3 fill-height">
              <v-col cols="10" align-self="center">
                <v-img
                  :src="require('@/assets/images/wellness/' + area.imageUrl)"
                  :max-height="imageHeight"
                  contain
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="7" align-self="center" class="text-start">
          <p class="mb-0 text-body-1 font-weight-bold">
            {{ area.title }}
          </p>
        </v-col>
      </v-row>
      <v-row v-if="openedDialogWithPain" class="mx-2">
        <v-col
          cols="5"
          :style="isShowingPainButton(area.value) ? 'height: 60px' : ''"
        >
          <v-btn
            class="no-hover align-with-pain-button"
            :ripple="false"
            icon
            color="primary"
            @click="$emit('updatePainArea')"
            v-show="isShowingPainButton(area.value)"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
            <span class="ml-2">
              {{ $t("onboarding.endUserFocusArea.updatePainArea") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import { FocusArea } from "@/services/deskassessment/focus-areas";

export default {
  name: "SelectGoal",
  emits: ["onGoalSelected", "updatePainArea"],
  props: {
    focusArea: Number,
    canUpdatePain: Boolean
  },
  data() {
    return {
      selectedFocusArea: this.focusArea
    };
  },
  methods: {
    selectFocusArea(area) {
      this.selectedFocusArea = area.value;
      this.$emit("onGoalSelected", area.value);
    },
    isFocusAreaSelected(area) {
      return area.value === this.selectedFocusArea;
    },
    isShowingPainButton(area) {
      return (
        area === FocusArea.ReducePain.intValue &&
        this.selectedFocusArea === area
      );
    }
  },
  computed: {
    openedDialogWithPain() {
      return (
        this.canUpdatePain && this.focusArea === FocusArea.ReducePain.intValue
      );
    },
    isHighZoomOrSmallScreen() {
      return this.highZoom || this.$vuetify.breakpoint.smAndDown;
    },
    focusAreas() {
      return [
        {
          id: "relievePain",
          value: FocusArea.ReducePain.intValue,
          title: this.$t("onboarding.endUserFocusArea.areas.relievePain"),
          color: "selectedPrimary",
          imageUrl: "relievePain.svg"
        },
        {
          id: "generalWellbeing",
          value: FocusArea.ImproveWellbeing.intValue,
          title: this.$t("onboarding.endUserFocusArea.areas.generalWellbeing"),
          color: "vitrueLightGreen",
          imageUrl: "generalWellbeing.svg"
        },
        {
          id: "workingPosture",
          value: FocusArea.FixPosture.intValue,
          title: this.$t("onboarding.endUserFocusArea.areas.workingPosture"),
          color: "vitruePalePurple",
          imageUrl: "workingPosture.svg"
        }
      ];
    },
    highZoom() {
      return window.devicePixelRatio > 1;
    },
    focusAreaSquareLength() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 100;
        case "sm":
          return 200;
        case "md":
          return 250;
        default:
          return this.highZoom ? 250 : 300;
      }
    },
    imageHeight() {
      let factor = this.$vuetify.breakpoint.xs ? 1 : 0.6;
      return Math.round(this.focusAreaSquareLength * factor);
    }
  }
};
</script>

<style scoped>
.selected {
  background-color: var(--v-vitruePaleGrey-base);
}

.no-hover::before {
  background-color: transparent !important;
}

.align-with-pain-button {
  justify-content: start;
  width: 100%;
  text-transform: none;
}
</style>
