var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticStyle: { overflow: "visible" }, attrs: { cols: "12" } },
    [
      _vm.$vuetify.breakpoint.smAndUp
        ? _c(
            "v-row",
            {
              class: _vm.isHighZoomOrSmallScreen ? "" : "mb-4",
              attrs: { justify: "space-around" }
            },
            _vm._l(_vm.focusAreas, function(area) {
              return _c(
                "div",
                [
                  _c("v-hover", {
                    key: area.id,
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var hover = ref.hover
                            return [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-3 ma-3",
                                  attrs: {
                                    id: area.id,
                                    color: _vm.isFocusAreaSelected(area)
                                      ? area.color
                                      : "",
                                    outlined: _vm.isFocusAreaSelected(area)
                                      ? true
                                      : false,
                                    elevation:
                                      hover || _vm.isFocusAreaSelected(area)
                                        ? "15"
                                        : "",
                                    "min-height": _vm.focusAreaSquareLength,
                                    height: _vm.focusAreaSquareLength,
                                    width: _vm.focusAreaSquareLength,
                                    ripple: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectFocusArea(area)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-3 fill-height",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "10",
                                            "align-self": "center"
                                          }
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              src: require("@/assets/images/wellness/" +
                                                area.imageUrl),
                                              "max-height": _vm.imageHeight,
                                              contain: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: {
                                            cols: "12",
                                            "align-self": "end"
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "mb-0 text-h6",
                                              class: _vm.isFocusAreaSelected(
                                                area
                                              )
                                                ? "font-weight-bold"
                                                : ""
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(area.title) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm.openedDialogWithPain
                    ? _c(
                        "v-row",
                        { attrs: { width: _vm.focusAreaSquareLength } },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { height: "60px" },
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isShowingPainButton(
                                        area.value
                                      ),
                                      expression:
                                        "isShowingPainButton(area.value)"
                                    }
                                  ],
                                  staticClass:
                                    "no-hover align-with-pain-button ml-2",
                                  attrs: {
                                    icon: "",
                                    ripple: false,
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("updatePainArea")
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-plus-circle-outline")
                                  ]),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "onboarding.endUserFocusArea.updatePainArea"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        : _vm._l(_vm.focusAreas, function(area) {
            return _c(
              "div",
              [
                _c(
                  "v-row",
                  {
                    key: area.id,
                    staticClass: "mx-2",
                    class: _vm.isFocusAreaSelected(area) ? "selected" : "",
                    on: {
                      click: function($event) {
                        return _vm.selectFocusArea(area)
                      }
                    }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "py-0", attrs: { cols: "5" } },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-3 ma-3",
                            attrs: {
                              id: area.id,
                              color: area.color,
                              "min-height": _vm.focusAreaSquareLength,
                              width: _vm.focusAreaSquareLength
                            }
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "mb-3 fill-height",
                                attrs: { justify: "center", align: "center" }
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "10",
                                      "align-self": "center"
                                    }
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: require("@/assets/images/wellness/" +
                                          area.imageUrl),
                                        "max-height": _vm.imageHeight,
                                        contain: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-start",
                        attrs: { cols: "7", "align-self": "center" }
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "mb-0 text-body-1 font-weight-bold" },
                          [_vm._v(" " + _vm._s(area.title) + " ")]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm.openedDialogWithPain
                  ? _c(
                      "v-row",
                      { staticClass: "mx-2" },
                      [
                        _c(
                          "v-col",
                          {
                            style: _vm.isShowingPainButton(area.value)
                              ? "height: 60px"
                              : "",
                            attrs: { cols: "5" }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isShowingPainButton(area.value),
                                    expression:
                                      "isShowingPainButton(area.value)"
                                  }
                                ],
                                staticClass: "no-hover align-with-pain-button",
                                attrs: {
                                  ripple: false,
                                  icon: "",
                                  color: "primary"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("updatePainArea")
                                  }
                                }
                              },
                              [
                                _c("v-icon", [
                                  _vm._v("mdi-plus-circle-outline")
                                ]),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "onboarding.endUserFocusArea.updatePainArea"
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }